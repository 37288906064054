import logo from './logo.svg';
import './App.css';



//INSTRUCTIONS
/*

Create a new React
npx create-react-app my-app
cd my-app
npm start

Install React Router Dom
npm install react-router-dom

Add pages folder in src folder

Add components folder in src folder



*/


function App() {


	var referallCodes = {
		"phil": {
			"isActive": false,
			"code": "phil187207"
		},
		"brendan": {
			"isActive": false,
			"code": "brendan34688"
		},
		"ryan": {
			"isActive": false,
			"code": "ryan62633"
		},
		"jonny": {
			"isActive": true,
			"code": "jonny282362"
		}
		
	}

	//get code where isActive is true
	var referralCode = "";
	for (var key in referallCodes) {
		if (referallCodes[key].isActive) {
			referralCode = referallCodes[key].code;
		}
	}




  return (
    <div className="App" >

		<h1>Save up to $1,000 with my referral code</h1>
		
		<h3>Use the links below to go to the Tesla website where you can save $1,000 on a purchase of your new Telsa.</h3>
	
		<a href={`https://www.tesla.com/modely/design?referral=${referralCode}&redirect=no`} className="referral-link">
			<div className="product-card model-y">
				Model Y
				<div className="order-now">Order Now</div>
			</div>
		</a>
		<a href={`https://www.tesla.com/model3/design?referral=${referralCode}&redirect=no`} className="referral-link">
			<div className="product-card model-3">
				Model 3
				<div className="order-now">Order Now</div>
			</div>
		</a>
		<a href={`https://www.tesla.com/models/design?referral=${referralCode}&redirect=no`} className="referral-link">
			<div className="product-card model-s">
				Model S
				<div className="order-now">Order Now</div>
			</div>
		</a>
		<a href={`https://www.tesla.com/modelx/design?referral=${referralCode}&redirect=no`} className="referral-link">
			<div className="product-card model-x">
				Model X
				<div className="order-now">Order Now</div>
			</div>
		</a>
		
		<p className="disclaimer">Tesla is currently offering up to $1,000 off your purchase of a new Tesla using a referral code. This website has no affiliation to Tesla. By clicking this link you will be re-drirected to the Tesla website where you can place the order and my referral code with your discount will be automatically applied. Tesla can change this program at any time, please check the Tesla website to ensure your discount is applied. </p>
    </div>
  );
}

export default App;
